import Button from 'components/common/next/form/button';
import { getLink } from '../../routes';
import MobileDeliveriesView from '../mobileOffers/mobileDeliveriesView';
import React from 'react';
import DeliveryStore from 'stores/next-retailer/deliveryStore';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';

interface Props {
  deliveryStore?: DeliveryStore;
}
const MobileOffersPage = ({ deliveryStore }: Props) => {
  const navigate = useNavigate();
  const onCreateClick = () => {
    deliveryStore.unsetCurrent();
    navigate(getLink('newMobileOffers'));
  };
  return (
    <div>
      <div className="main-content">
        <div className="content">
          <h2>Aktivoitavat mobiiliedut</h2>
          <div>
            <p>
              Kaupat voivat lähettää aktivoitavia mobiilietuja halutun kohderyhmän K-Ruoka -mobiilisovellusta
              käyttäville asiakkaille. Edut ovat kohdennettuja etuja ja näkyvät aktivoinnin jälkeen myös K-Ruoka.fi
              sivustolla. Edut asetetaan kaikille edun vastaanottajan talouden jäsenille. Jos talouden jäsenellä ei ole
              mobiilisovellusta asennettuna edun luontihetkellä, etu on silti saatavilla sovelluksen asentamisen jälkeen
              edun voimassaoloaikana.
            </p>
            <p>
              Eduista ilmoitetaan asiakkaalle priorisoidulla push-viestillä. Priorisoitu push-viesti tarkoittaa sitä,
              että se ohittaa lähetysjonossa mahdolliset ketjun lähettämät viestit.
            </p>
            <p>
              <h4>HUOM! Koska etu tarjotaan rajatulle kohderyhmälle, johon asiakas ei voi itse liittyä, sitä ei voi markkinoida kaupan omissa markkinointimateriaaleissa.</h4>
              
            </p>
            <Button onClick={onCreateClick}>Luo aktivoitavia mobiilietuja</Button>
          </div>
          <MobileDeliveriesView />
        </div>
      </div>
    </div>
  );
};
export default inject('deliveryStore')(observer(MobileOffersPage));
