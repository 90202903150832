import React, { ChangeEvent, Fragment } from 'react';
import { addWeekDays, castDate, subWeekdays } from 'utils/helpers';
import './datesBox.scss';
import { get } from 'lodash';
import { DeliveryWithMeta, getFirstPossibleStartDateForDelivery } from 'stores/next-retailer/deliveryStore';
import { DeliveryTemplateWithMeta } from 'stores/next-retailer/templateStore';
import Button from '../../../../../../common/next/form/button';
import * as date from 'date-fns';
import fi from 'date-fns/locale/fi';
import { DatePicker } from '@material-ui/pickers';
import { toJS } from 'mobx';
import { ConceptType } from 'enums/common';
import { DeliveryState } from 'types/delivery';

interface Props {
  conceptType: ConceptType;
  delivery: DeliveryWithMeta;
  template: DeliveryTemplateWithMeta;
  setDeliveryField: (field: string, value: any) => any;
  disabled?: boolean;
  showDates: boolean;
  mobileOffers?: boolean;
}

class DatesBox extends React.Component<Props> {
  get delivery() {
    return this.props.delivery;
  }

  get template() {
    return this.props.template;
  }

  get deliveryId() {
    return this.delivery.id || 'new';
  }

  get templateId() {
    return this.delivery.deliveryTemplate;
  }

  get isStartDateEditable() {
    return new Date(this.firstPossibleStartDate).toString() !== new Date(this.template.lastStartDate).toString();
  }

  get isPrintSelected() {
    return this.delivery.targetGroup.channels.print !== 0;
  }

  get isEmailSelected() {
    return this.delivery.targetGroup.channels.email !== 0;
  }

  get firstPossibleStartDate() {
    return getFirstPossibleStartDateForDelivery(this.delivery, this.template, this.props.conceptType);
  }

  get offerDuration() {
    const startDate = this.template.offerOpts.startDate || this.delivery.offerStartDate;
    const endDate = this.template.offerOpts.endDate || this.delivery.offerEndDate;
    return date.differenceInDays(castDate(endDate), castDate(startDate)) + 1;
  }

  // eslint-disable-next-line
  async componentDidMount() {
    this.sanityCheckDates();
  }

  setDeliveryField = (field: string, value: any) => {
    this.props.setDeliveryField(field, value);
  };

  handleChange = (field: string) => async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // eslint-disable-next-line
    await this.setDeliveryField(field, value);
    if (field === 'startDate') {
      await this.maybeUpdateOfferDates();
    }
    this.sanityCheckDates();
  };

  getDeliveryDates = () => {
    const startDate = new Date(this.delivery.startDate); // retailer selected value
    const emailDeliveryDate = startDate;
    const printDeliveryFirstDate = subWeekdays(startDate, 3); // first date print delivery might be delivered
    const printDeliveryLastDate = addWeekDays(printDeliveryFirstDate, 4); // last date print delivery might be delivered
    return { startDate, emailDeliveryDate, printDeliveryFirstDate, printDeliveryLastDate };
  };

  resetDates = async () => {
    // eslint-disable-next-line
    await this.setDeliveryField('startDate', date.format(castDate(this.firstPossibleStartDate), 'yyyy-MM-dd'));
    this.sanityCheckDates();
    this.setDeliveryOfferDates(this.firstPossibleStartDate);
  };

  maybeUpdateOfferDates = async () => {
    const { emailDeliveryDate } = this.getDeliveryDates();
    if (new Date(this.delivery.offerStartDate) > emailDeliveryDate) {
      // eslint-disable-next-line
      await this.setDeliveryField('offerStartDate', date.format(castDate(emailDeliveryDate), 'yyyy-MM-dd'));
      this.sanityCheckDates();
    }
  };

  sanityCheckDates = () => {
    const today = new Date();
    const { mobileOffers } = this.props;
    if (
      mobileOffers &&
      this.delivery.state === DeliveryState.draft &&
      new Date(this.delivery.offerStartDate) < this.firstPossibleStartDate
    ) {
      this.setDeliveryOfferDates(this.firstPossibleStartDate);
    }
    if (this.firstPossibleStartDate > new Date(this.delivery.startDate)) {
      this.setDeliveryField('startDate', date.format(castDate(this.firstPossibleStartDate), 'yyyy-MM-dd'));
    }
    if (today > new Date(this.delivery.offerStartDate)) {
      this.setDeliveryField('offerStartDate', date.format(castDate(today), 'yyyy-MM-dd'));
    }
    if (new Date(this.delivery.offerEndDate) <= new Date(this.delivery.offerStartDate)) {
      this.setDeliveryField('offerEndDate', this.delivery.offerStartDate);
    }
    if (new Date(this.template.lastStartDate) < new Date(this.delivery.startDate)) {
      this.setDeliveryField('startDate', this.template.lastStartDate);
    }
  };

  isoDate = (input: any) => date.format(castDate(input), 'yyyy-MM-dd');

  setDeliveryDate = (newDate) => {
    this.setDeliveryField('startDate', this.isoDate(newDate));
    this.setDeliveryOfferDates(newDate);
  };

  setDeliveryOfferDates = (newDate) => {
    this.setDeliveryField('offerStartDate', this.isoDate(newDate));
    const offerEnd = get(this.template, ['offerOpts', 'endDate']);
    const offerDays = get(this.template, ['offerOpts', 'defaultLength'], 7);
    const offerEndDate = offerEnd
      ? date.min([date.addDays(castDate(newDate), offerDays - 1), castDate(offerEnd)])
      : date.addDays(castDate(newDate), offerDays - 1);
    this.setDeliveryField('offerEndDate', this.isoDate(offerEndDate));
  };

  maybeRenderOfferDateNotice = () => {
    const notices: string[] = [];
    const { emailDeliveryDate, printDeliveryLastDate } = this.getDeliveryDates();
    notices.push(`Edut ovat voimassa <strong>${this.offerDuration}</strong> päivää.`);

    const makeSure = [];
    if (
      this.isEmailSelected &&
      new Date(this.delivery.offerStartDate) > emailDeliveryDate &&
      !this.props.mobileOffers
    ) {
      makeSure.push('viimeistään sähköpostilähetyksen päivänä');
    }
    if (
      this.isEmailSelected &&
      new Date(this.delivery.offerEndDate) < printDeliveryLastDate &&
      !this.props.mobileOffers
    ) {
      makeSure.push('vähintään printin jakelun loppuun asti');
    }

    if (makeSure.length > 0) {
      notices.push(`Varmista, että edut ovat voimassa ${makeSure.join(' ')}.`);
    }

    return notices.join(' ');
  };

  renderDeliveryStartPicker = () => {
    const deliveryWeekdays = toJS(this.template.deliveryWeekdays);
    const shouldDisableDate = (d: Date) => {
      if (deliveryWeekdays) {
        return !deliveryWeekdays.includes(date.getISODay(d) - 1);
      }
      return false;
    };
    return (
      <Fragment>
        <DatePicker
          value={this.delivery.startDate}
          minDate={this.isoDate(this.firstPossibleStartDate)}
          maxDate={this.isoDate(this.template.lastStartDate)}
          onChange={this.setDeliveryDate}
          disabled={!this.isStartDateEditable || this.props.disabled}
          format="d.M.yyyy"
          disableToolbar
          shouldDisableDate={shouldDisableDate}
        />
        {this.isStartDateEditable && (
          <Button onClick={this.resetDates} color="bordered" disabled={this.props.disabled}>
            Seuraava mahdollinen
          </Button>
        )}
      </Fragment>
    );
  };

  render() {
    const { emailDeliveryDate, printDeliveryFirstDate, printDeliveryLastDate } = this.getDeliveryDates();
    const { offerOpts } = this.props.template;

    const forcedStartDate = offerOpts.startDate || false;
    const forcedEndDate = offerOpts.endDate || false;

    return (
      <div className="box delivery-dates">
        {!this.props.mobileOffers && (
          <div className="box-section">
            <h3>{this.isPrintSelected ? 'Viestit aikaisintaan asiakkaalla' : 'Lähetysaika'}</h3>
            <div className="input-container">{this.renderDeliveryStartPicker()}</div>
            <p className="text-small">
              {this.isPrintSelected && (
                <span>
                  Printin jakelu:{' '}
                  <strong>
                    {date.format(castDate(printDeliveryFirstDate), 'iiiiii d.M.', { locale: fi })}
                    {' - '}
                    {date.format(castDate(printDeliveryLastDate), 'iiiiii d.M.', { locale: fi })}
                  </strong>
                  <br />
                </span>
              )}
              {this.isEmailSelected && (
                <span>
                  Sähköpostilähetys:{' '}
                  <strong>{date.format(castDate(emailDeliveryDate), 'iiiiii d.M.', { locale: fi })}</strong>
                  <br />
                </span>
              )}
            </p>
          </div>
        )}
        {this.props.showDates === true ? (
          <div className="box-section">
            <h3>Edut voimassa</h3>
            <div className="input-container">
              <DatePicker
                value={forcedStartDate || this.delivery.offerStartDate}
                minDate={
                  this.props.mobileOffers
                    ? this.isoDate(this.firstPossibleStartDate)
                    : this.isoDate(this.delivery.startDate)
                }
                maxDate={this.props.mobileOffers ? undefined : date.addDays(new Date(this.delivery.startDate), 14)}
                onChange={this.setDeliveryOfferDates}
                format="d.M.yyyy"
                disableToolbar
                disabled={this.props.disabled || forcedStartDate !== false}
              />
              <strong className="separator">&mdash;</strong>
              <DatePicker
                value={forcedEndDate || this.delivery.offerEndDate}
                minDate={this.delivery.offerStartDate}
                onChange={(date) => this.setDeliveryField('offerEndDate', this.isoDate(date))}
                format="d.M.yyyy"
                disableToolbar
                disabled={this.props.disabled || forcedStartDate !== false}
              />
            </div>
            <p className="text-small" dangerouslySetInnerHTML={{ __html: this.maybeRenderOfferDateNotice() }} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default DatesBox;
