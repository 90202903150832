import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import _ from 'utils/lodash';
import { format, parse } from 'date-fns';
import { inject, observer } from 'mobx-react';
import { DatePicker } from '@material-ui/pickers';
import LanguageSelector from 'components/common/next/languageSelector';
import { ReactComponent as IconInfo } from '@kesko/icons/attention/icon-info.svg';

import DrawerContent from 'components/retailer/next/components/wrappers/drawerContent';
import { CustomField } from 'components/next/components/form/input';
import './contentForm.scss';
import type { ContentTemplate, DeliverySlot, ContentBlock, ContentField, DeliveryLanguage, Delivery } from 'types/next';
import { getLink } from 'components/retailer/next/routes';
import Button from 'components/common/next/form/button';
import InputSelect from 'components/common/next/form/inputSelect';
import InputText from 'components/common/next/form/inputText';
import InputProduct from 'components/common/next/form/inputProduct';
import { toJS } from 'mobx';
import TemplateStore from 'stores/next-retailer/templateStore';
import DeliveryStore, { DeliveryRelations, DeliveryWithMeta } from 'stores/next-retailer/deliveryStore';
import ContentStore from 'stores/next-retailer/contentStore';
import InputImage from 'components/common/next/form/inputImage';
import InputLink from 'components/common/next/form/inputLink';
import InfoPopover from 'components/retailer/next/components/common/infoPopover';
import { ReactComponent as ChevronRight } from '@kesko/icons/nav/icon-chevron-right.svg';
import AlertStore from 'stores/next/alerts';
import { todoRegexp, linkRegexp } from 'types/delivery';
import { DeliveryChannelName, ContentFieldType, PreviewFormat } from 'enums/common';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/common/next/spinner';

interface Props {
  templateStore?: TemplateStore;
  deliveryStore?: DeliveryStore;
  contentStore?: ContentStore;
  alertStore?: AlertStore;
}

type Params = {
  templateId: string;
  deliveryId: string;
  channel: DeliveryChannelName;
};

const ContentForm = ({ templateStore, deliveryStore, contentStore, alertStore }: Props) => {
  const { templateId: templateIdParam, deliveryId: deliveryIdParam, channel: channelParam } = useParams<Params>();
  const navigate = useNavigate();
  const [language, setLanguage] = useState<DeliveryLanguage>('fi');
  const inputImageRefs = useRef({});

  const isNew = () => {
    return deliveryIdParam === 'new';
  };

  const getDelivery = () => {
    // use current delivery
    return deliveryStore.current;
  };

  const getTemplate = () => {
    return _.find(templateStore.templates, { id: templateIdParam });
  };

  const getLoadingPreview = () => {
    if (channelParam === DeliveryChannelName.Email) {
      return getDelivery().emailPreviewLargeLoading;
    }
    if (channelParam === DeliveryChannelName.Print) {
      return getDelivery().printPreviewPdfLoading;
    }
    return false;
  };

  useEffect(() => {
    const delivery = getDelivery();
    if (!delivery || (!isNew() && delivery.id !== deliveryIdParam)) {
      navigate(getLink('editDelivery', deliveryIdParam, templateIdParam));
    }
    const init = async () => {
      if (delivery && !delivery.targetGroup.languages.includes('fi')) {
        setLanguage(delivery.targetGroup.languages[0]);
      }
      if (!getTemplate()) {
        await templateStore.getDeliveryTemplate(templateIdParam);
      }
    };
    init();
  }, []);

  const infoButton = (opts: { slug: string; link?: string }) => {
    return (
      <InfoPopover>
        <div
          className="help-content"
          dangerouslySetInnerHTML={{
            __html: contentStore.getContentBySlug(opts.slug, { html: true }),
          }}
        />
        {opts.link && (
          <footer>
            <a href={opts.link} target="_blank" rel="noopener noreferrer">
              Avaa ohjeet uudessa ikkunassa
              <ChevronRight />
            </a>
          </footer>
        )}
      </InfoPopover>
    );
  };

  const templateHasChannel = (name: 'email' | 'print') => {
    return Boolean(_.find(getTemplate().channels, { name }));
  };

  const getContentTemplate = (templateId: string) => {
    // If for some reason selected channel template is null, use first content template for that channel.
    if (_.isNil(templateId)) {
      const defaultTemplate = _.find(getTemplate().contentTemplates, {
        channel: channelParam as DeliveryChannelName,
      }) as ContentTemplate;
      // Trigger template change so default template gets saved.
      handleTemplateChange({ target: { value: defaultTemplate.id } } as ChangeEvent<HTMLSelectElement>);
      return defaultTemplate;
    }

    return _.find(getTemplate().contentTemplates, { id: templateId }) as ContentTemplate;
  };

  const getDeliveryField = (fieldId: string) => {
    const deliveryField = _.find(getDelivery().deliveryFields, { contentField: fieldId });
    if (deliveryField) {
      return deliveryField;
    }
  };

  const isValidLink = (link: string) => {
    if (!link) {
      return true;
    }

    const isValidLink = linkRegexp.test(link);
    return isValidLink;
  };

  const isValidDeliveryFieldValue = (value: any) => {
    const parsedValue = JSON.parse(value);
    const keysToValidate = _.intersection(Object.keys(parsedValue), getDelivery().targetGroup.languages);
    const stringKeysToValidate = keysToValidate.filter((key) => _.isString(parsedValue[key]));
    return stringKeysToValidate.every((key) => {
      return !parsedValue[key].match(todoRegexp);
    });
  };

  const handleTemplateChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const templateId = e.target.value === '' ? null : e.target.value;
    const delivery = toJS(getDelivery());
    const contentTemplate = getContentTemplate(templateId);
    if (channelParam === DeliveryChannelName.Email) {
      delivery.emailContentTemplate = templateId;
      delivery.emailSubject = contentTemplate.emailDefaultSubject;
    }
    if (channelParam === DeliveryChannelName.Print) {
      delivery.printContentTemplate = templateId;
    }
    deliveryStore.setCurrent(delivery);
  };

  const handleBlockChange = (slotId: string) => {
    return (e: ChangeEvent<HTMLSelectElement>) => {
      const contentBlockId = e.target.value === '' ? null : e.target.value;
      const deliverySlots = getDelivery().deliverySlots || [];
      const deliverySlotIdx = _.findIndex(deliverySlots, { contentSlot: slotId });
      if (deliverySlotIdx !== -1) {
        deliverySlots[deliverySlotIdx].contentBlock = contentBlockId;
      } else {
        deliverySlots.push({
          contentSlot: slotId,
          contentBlock: contentBlockId,
        });
      }

      // save changes
      const delivery = toJS(getDelivery());
      delivery.deliverySlots = deliverySlots;
      deliveryStore.setCurrent(delivery);
    };
  };

  const handleSimpleFieldChange = (fieldId: string, lang: DeliveryLanguage) => {
    return (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      updateDeliveryField(fieldId, value, lang);
    };
  };

  const handleDateFieldChange = (fieldId: string, lang: DeliveryLanguage) => {
    return (date: Date) => {
      const value = format(date, 'd.M.yyyy');
      updateDeliveryField(fieldId, value, lang);
    };
  };

  const handleImageFieldChange = (fieldId: string, lang: DeliveryLanguage) => {
    return ({ src, text }: { src: string; text: string }) => {
      updateDeliveryField(fieldId, { src, text }, lang);
    };
  };

  const handleLinkFieldChange = (fieldId: string, lang: DeliveryLanguage) => {
    return ({ text, href }: { text: string; href: string }) => {
      updateDeliveryField(fieldId, { text, href }, lang);
    };
  };

  const handleBttPromotedProductFieldChange = (fieldId: string, lang: DeliveryLanguage) => {
    return ({
      title,
      description,
      url,
      normalPriceVat0,
      normalPriceVat24,
      salePriceVat24,
      loyaltyPrice,
      pricingUnit,
      ean,
      productNumber,
      src,
      isOnLoyaltySale,
    }: {
      title: string;
      description: string;
      url: string;
      normalPriceVat0: number;
      normalPriceVat24: number;
      salePriceVat24: number;
      loyaltyPrice: number;
      pricingUnit: string;
      ean: string;
      productNumber: string;
      src: string;
      isOnLoyaltySale: boolean;
    }) => {
      updateDeliveryField(
        fieldId,
        {
          title,
          description,
          url,
          normalPriceVat0,
          normalPriceVat24,
          salePriceVat24,
          loyaltyPrice,
          pricingUnit,
          ean,
          productNumber,
          src,
          isOnLoyaltySale,
        },
        lang,
      );
    };
  };

  const handleEmailSubjectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const delivery = toJS(getDelivery());
    const emailSubject = delivery.emailSubject || {};
    emailSubject[language] = e.target.value;
    delivery.emailSubject = emailSubject;
    deliveryStore.setCurrent(delivery);
  };

  const updateDeliveryField = (fieldId: string, val: any, lang: DeliveryLanguage) => {
    const deliveryFields = getDelivery().deliveryFields || [];
    const deliveryFieldIdx = _.findIndex(deliveryFields, { contentField: fieldId });
    if (deliveryFieldIdx !== -1) {
      const currentValue = deliveryFields[deliveryFieldIdx].value[lang] as any;

      // If incoming value is object (e.g. in image field because there are src and text), merge current value with new.
      deliveryFields[deliveryFieldIdx].value[lang] = _.isObject(val) ? { ...currentValue, ...val } : val;
    } else {
      const value = {};
      value[lang] = val;
      deliveryFields.push({
        contentField: fieldId,
        value,
      });
    }

    // save changes
    const delivery = toJS(getDelivery());
    delivery.deliveryFields = deliveryFields;
    deliveryStore.setCurrent(delivery);
  };

  const fetchEntity = async (previewEntity: DeliveryWithMeta, previewEntityRef: any) => {
    const entity = previewEntity;
    if (!entity) {
      return null;
    }
    if (deliveryIdParam === 'new') {
      if (channelParam === DeliveryChannelName.Print) {
        deliveryStore.previewDelivery(
          entity as Delivery & DeliveryRelations,
          {
            channel: DeliveryChannelName.Print,
            format: PreviewFormat.Pdf,
          },
          previewEntityRef,
        );
      }
      if (channelParam === DeliveryChannelName.Email) {
        // deliveryStore.previewDelivery(
        //   entity as Delivery & DeliveryRelations,
        //   {
        //     channel: DeliveryChannelName.Email,
        //     format: PreviewFormat.Html,
        //   },
        //   previewEntityRef,
        // );
        deliveryStore.previewDelivery(
          entity as Delivery & DeliveryRelations,
          {
            channel: DeliveryChannelName.Email,
            format: PreviewFormat.Image,
          },
          previewEntityRef,
        );
      }
    }
    if (deliveryIdParam !== 'new') {
      await deliveryStore.getDelivery(deliveryIdParam, false);
      if (channelParam === DeliveryChannelName.Print) {
        deliveryStore.previewDelivery(
          entity as Delivery & DeliveryRelations,
          {
            channel: channelParam as DeliveryChannelName,
            format: PreviewFormat.Pdf,
          },
          previewEntityRef,
        );
      }
      if (channelParam === DeliveryChannelName.Email) {
        // deliveryStore.previewDelivery(
        //   entity as Delivery & DeliveryRelations, {
        //     channel: DeliveryChannelName.Email,
        //     format: PreviewFormat.Html,
        //   },
        //   previewEntityRef
        // );
        deliveryStore.previewDelivery(
          entity as Delivery & DeliveryRelations,
          {
            channel: DeliveryChannelName.Email,
            format: PreviewFormat.Image,
          },
          previewEntityRef,
        );
      }
    }
  };

  const exit = async () => {
    // Filter out if no ref
    const unsavedImages = Object.keys(inputImageRefs.current)
      .filter((key) => inputImageRefs.current[key])
      .filter((key) => inputImageRefs.current[key].getImageFile() !== undefined);

    const deliveryFields = getContentBlocksAndFields();
    if (deliveryFields.length > 0) {
      const invalidLinks = deliveryFields
        .filter((field) => field)
        .filter((field) => field.type === ContentFieldType.link)
        .filter((field) => {
          const deliveryField = getDeliveryField(field.id) as Record<string, any>;
          const link = deliveryField.value[language].href;
          return !isValidLink(link);
        });

      if (invalidLinks.length > 0) {
        alertStore.error({
          message: 'Tarkasta linkkien muotoilu',
        });
        return;
      }
    }
    let confirmed = true;
    if (unsavedImages.length > 0) {
      const unsavedImageFields = deliveryFields
        .filter((f) => f)
        .filter((f) => unsavedImages.includes(f.identifier))
        .map((f) => f.title[language]);
      confirmed = window.confirm(
        `Lähetyksessä on tallentamattomia kuvia: ${JSON.stringify(
          unsavedImageFields,
        )}. Poistutaanko tallentamatta kuvia?`,
      );
    }

    // Call the preview update here
    const previewEntityRef = { current: true };
    const previewEntity = deliveryStore.getEntityForRef(previewEntityRef);
    if (previewEntity.id === deliveryIdParam || (!previewEntity.id && deliveryIdParam === 'new')) {
      // Just make sure that the correct delivery is being updated..
      await fetchEntity(previewEntity, previewEntityRef);
    }
    if (confirmed) {
      alertStore.success({
        message: 'Muutokset hyväksytty',
        link: `preview/delivery/${channelParam}/new`,
      });
      navigate(getLink('editDelivery', deliveryIdParam, templateIdParam), { state: { disableNotification: true } });
    }
  };

  const getContentBlocksAndFields = () => {
    const { contentBlocks, contentFields } = getTemplate();

    const selectedContentTemplateId =
      channelParam === DeliveryChannelName.Email
        ? getDelivery().emailContentTemplate
        : getDelivery().printContentTemplate;
    const selectedContentTemplate = getContentTemplate(selectedContentTemplateId);

    const slots = selectedContentTemplate ? selectedContentTemplate.contentSlots : [];
    const blocksWithFields = contentBlocks.map((block) => {
      return {
        ...block,
        fields: contentFields.filter((f) => block.contentFieldIdentifiers.includes(f.identifier)),
      };
    });
    const slotsWithBlocks = slots.map((slot) => {
      return {
        ...slot,
        blocks: blocksWithFields.filter((b) => slot.contentBlocks.includes(b.id)),
      };
    });

    const selectedBlocks = slotsWithBlocks.map((slot) => {
      const deliverySlot: DeliverySlot = _.find(getDelivery().deliverySlots, { contentSlot: slot.id });
      const selectedBlockId = deliverySlot && deliverySlot.contentBlock;
      const selectedBlock: ContentBlock & { fields: ContentField[] } = _.find(blocksWithFields, {
        id: selectedBlockId,
      });
      if (selectedBlock) {
        return _.orderBy(selectedBlock.fields, 'order');
      }
    });
    return selectedBlocks.flat();
  };

  const preview = () => {
    navigate(`preview/delivery/${channelParam}/new`, { state: { disableNotification: true } });
  };

  const renderHeader = () => {
    return <h2>Muokkaa sisältöä ({channelParam === DeliveryChannelName.Email ? 'sähköposti' : 'printti'})</h2>;
  };

  const renderFooter = () => {
    return (
      <div className="content-form-footer">
        <div className="actions">
          <Button color="bordered" onClick={preview}>
            Esikatsele
          </Button>
          {getLoadingPreview() && <Spinner />}
        </div>
        <div className="actions">
          <Button color="bordered" onClick={exit}>
            Hyväksy
          </Button>
        </div>
      </div>
    );
  };

  const renderDeliveryField = (field: ContentField) => {
    const deliveryField = getDeliveryField(field.id);
    const isReadOnly = field.readOnly;
    const isRequiredReadOnly = field.required && isReadOnly;
    const value = isRequiredReadOnly
      ? _.get(field, ['defaultValue', language])
      : _.get(deliveryField, ['value', language], '');
    const isValidValue = isValidDeliveryFieldValue(JSON.stringify(deliveryField.value));
    const validationError =
      'Korvaa TODO sisällöllä' + (isMultiLanguageDelivery() ? ' (huomaa tarkistaa myös eri kielet)' : '');
    const maxLength =
      field.props && field.props['maxLength'] && Number(field.props['maxLength']) > 0
        ? Number(field.props['maxLength'])
        : undefined;

    switch (field.type) {
      case ContentFieldType.image: {
        return (
          <InputImage
            key={field.identifier}
            label={field.title[language]}
            value={value as { src: string; text?: string }}
            onChange={handleImageFieldChange(field.id, language)}
            withText={_.get(field, ['props', 'withText'])}
            readOnly={isReadOnly || isRequiredReadOnly}
            ref={(el) => (inputImageRefs.current[field.identifier] = el)}
          />
        );
      }
      case ContentFieldType.richtext: {
        return (
          <React.Fragment key={`${field.identifier}-input`}>
            <InputText
              textarea
              key={field.identifier}
              name={field.identifier}
              label={field.title[language]}
              value={value as string}
              onChange={handleSimpleFieldChange(field.id, language)}
              readOnly={isReadOnly || isRequiredReadOnly}
              maxLength={maxLength}
            />
            {!isValidValue && <div className="input-validation-error">{validationError}</div>}
            {maxLength && (
              <span className="char-count">{getCharCountByIdentifier(field.identifier, field.props['maxLength'])}</span>
            )}
          </React.Fragment>
        );
      }
      case ContentFieldType.link:
        return (
          <React.Fragment key={`${field.identifier}-link`}>
            <InputLink
              key={field.identifier}
              label={field.title[language]}
              value={value as { text: string; href: string }}
              name={field.identifier}
              onChange={handleLinkFieldChange(field.id, language)}
              readOnly={isReadOnly || isRequiredReadOnly}
            />
            {!isValidLink((value as { text: string; href: string }).href) && (
              <p className="input-validation-error">
                Tarkasta linkin muotoilu. Linkin tulee alkaa joko https://, http:// tai mailto: -merkinnällä, eikä se
                voi olla tyhjä.
              </p>
            )}
            {!isValidValue && <div className="input-validation-error">{validationError}</div>}
          </React.Fragment>
        );
      case 'select': {
        const selectOptions: string[] = _.get(field, ['props', 'options'], '').split(',');
        return isReadOnly || isRequiredReadOnly ? (
          <InputText
            key={field.identifier}
            name={field.identifier}
            label={field.title[language]}
            value={value as string}
            readOnly
          />
        ) : (
          <InputSelect
            key={field.identifier}
            label={field.title[language]}
            value={value as string}
            onChange={handleSimpleFieldChange(field.id, language)}
          >
            {selectOptions.map((option, i) => {
              return (
                <option key={`${field.identifier}-option-${i}`} value={option}>
                  {option}
                </option>
              );
            })}
          </InputSelect>
        );
      }
      case ContentFieldType.date: {
        return isReadOnly || isRequiredReadOnly ? (
          <InputText
            key={field.identifier}
            name={field.identifier}
            label={field.title[language]}
            value={value as string}
            readOnly
          />
        ) : (
          <CustomField key={field.identifier} label={field.title[language]} additionalClasses="form-control">
            <DatePicker
              className="datepicker"
              value={parse(value as string, 'd.M.yyyy', new Date())}
              onChange={handleDateFieldChange(field.id, language)}
              format="d.M.yyyy"
              disableToolbar
            />
          </CustomField>
        );
      }

      case ContentFieldType.bttPromotedProduct: {
        return (
          <React.Fragment key={`${field.identifier}-link`}>
            <InputProduct
              key={field.identifier}
              label={field.title[language]}
              value={
                value as {
                  title: string;
                  description: string;
                  url: string;
                  normalPriceVat0: number;
                  normalPriceVat24: number;
                  salePriceVat24: number;
                  loyaltyPrice: number;
                  pricingUnit: string;
                  ean: string;
                  productNumber: string;
                  isOnLoyaltySale: boolean;
                }
              }
              name={field.identifier}
              onChange={handleBttPromotedProductFieldChange(field.id, language)}
              readOnly={isReadOnly || isRequiredReadOnly}
            />
            <InputImage
              key={`${field.identifier}-image`}
              label={'Tuotekuva'}
              value={value as { src: string; text?: string }}
              onChange={handleImageFieldChange(field.id, language)}
              withText={_.get(field, ['props', 'withText'])}
              readOnly={isReadOnly || isRequiredReadOnly}
              ref={(el) => (inputImageRefs.current[`${field.identifier}-image`] = el)}
            />
          </React.Fragment>
        );
      }
      default: {
        return (
          <React.Fragment key={`${field.identifier}-input`}>
            <InputText
              key={field.identifier}
              name={field.identifier}
              label={field.title[language]}
              value={value as string}
              onChange={handleSimpleFieldChange(field.id, language)}
              readOnly={isReadOnly || isRequiredReadOnly}
              maxLength={maxLength}
            />
            {!isValidValue && <div className="input-validation-error">{validationError}</div>}
            {maxLength && (
              <span className="char-count">{getCharCountByIdentifier(field.identifier, field.props['maxLength'])}</span>
            )}
          </React.Fragment>
        );
      }
    }
  };

  const getCharCountByIdentifier = (identifier: string, maxLength: number) => {
    const element = document.getElementsByName(identifier)[0] as HTMLInputElement;
    if (element) {
      return `${element.value.length}/${maxLength} merkkiä käytetty`;
    }
    if (!element) {
      return '';
    }
  };

  const toggleLanguage = (language: DeliveryLanguage) => {
    setLanguage(language);
  };

  const isMultiLanguageDelivery = () => getDelivery().targetGroup.languages.includes('sv');

  if (!getTemplate() || !getDelivery()) {
    return <Spinner />;
  }

  const render = () => {
    const template = getTemplate();
    const contentTemplates = template.contentTemplates.filter((t) => t.channel === channelParam);
    const contentBlocks = template.contentBlocks;
    const contentFields = template.contentFields;

    const selectedContentTemplateId =
      channelParam === DeliveryChannelName.Email
        ? getDelivery().emailContentTemplate
        : getDelivery().printContentTemplate;
    const selectedContentTemplate = getContentTemplate(selectedContentTemplateId);

    const slots = selectedContentTemplate.contentSlots;

    const templateWithFields = {
      ...selectedContentTemplate,
      fields: contentFields.filter((f) => selectedContentTemplate.contentFieldIdentifiers.includes(f.identifier)),
    };

    const blocksWithFields = contentBlocks.map((block) => {
      return {
        ...block,
        fields: contentFields.filter((f) => block.contentFieldIdentifiers.includes(f.identifier)),
      };
    });
    const slotsWithBlocks = slots.map((slot) => {
      return {
        ...slot,
        blocks: blocksWithFields.filter((b) => slot.contentBlocks.includes(b.id)),
      };
    });
    return (
      <>
        <DrawerContent className="content-form" renderHeader={renderHeader} renderFooter={renderFooter}>
          <div className="languages">
            Muokkaa kieltä
            <LanguageSelector
              language={language}
              languages={getDelivery().targetGroup.languages}
              toggleLanguage={toggleLanguage}
            />
            {isMultiLanguageDelivery() && (
              <span className="detail-text">
                <IconInfo /> Muista muokata myös ruotsinkielinen sisältö
              </span>
            )}
          </div>
          <div className="content-template-select">
            <InputSelect
              label="Valittu Pohja"
              className="template-options"
              value={selectedContentTemplateId}
              onChange={handleTemplateChange}
            >
              {contentTemplates.map((template) => {
                return (
                  <option key={template.id} value={template.id}>
                    {template.title[language]}
                  </option>
                );
              })}
            </InputSelect>
            {infoButton({ slug: 'info-retailer-template', link: getLink('help', 'info-retailer-template') })}
          </div>
          <div className="content-template">
            {(channelParam === DeliveryChannelName.Email || templateWithFields.fields.length > 0) && (
              <div className="content-template-fields slot">
                <h3>Yleiset</h3>
                {infoButton({ slug: 'info-content-general', link: getLink('help', 'info-content-general') })}
                {channelParam === DeliveryChannelName.Email && (
                  <InputText
                    name={`emailSubject.${language}`}
                    label={`Sähköpostin aihe - ${language}`}
                    value={_.get(getDelivery(), ['emailSubject', language])}
                    onChange={handleEmailSubjectChange}
                  />
                )}
                {_.orderBy(templateWithFields.fields, 'order').map((field) => renderDeliveryField(field))}
              </div>
            )}
            {slotsWithBlocks.map((slot) => {
              const deliverySlot: DeliverySlot = _.find(getDelivery().deliverySlots, { contentSlot: slot.id });
              const selectedBlockId = deliverySlot && deliverySlot.contentBlock;
              const selectedBlock: ContentBlock & { fields: ContentField[] } = _.find(blocksWithFields, {
                id: selectedBlockId,
              });
              return (
                <div key={slot.id} className={`slot slot-${slot.id}`}>
                  <h3>{slot.title.fi}</h3>
                  {infoButton({ slug: 'info-retailer-slot', link: getLink('help', 'info-retailer-slot') })}
                  <InputSelect
                    label="Valittu sisältö"
                    className="block-options"
                    value={selectedBlockId || ''}
                    onChange={handleBlockChange(slot.id)}
                  >
                    {!slot.required && <option value="">Ei valittu</option>}
                    {slot.blocks.map((block) => {
                      return (
                        <option key={block.id} value={block.id}>
                          {block.title[language]}
                        </option>
                      );
                    })}
                  </InputSelect>
                  {selectedBlock && (
                    <div className={`block block-${selectedBlock.id}`}>
                      {_.orderBy(selectedBlock.fields, 'order').map(renderDeliveryField)}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <pre className="debug">{JSON.stringify({ blocksWithFields }, null, 2)}</pre>
        </DrawerContent>
        <Outlet />
      </>
    );
  };

  return render();
};

export default inject('templateStore', 'deliveryStore', 'contentStore', 'alertStore')(observer(ContentForm));
